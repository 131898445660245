
.sectionTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 20px;
}

.notificationContainer {
  border: 1px solid rgba(237, 238, 242, 1);

}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
}

.notificationTitle {
  font-weight: 500;
  font-size: 13px;
  display: rgba(27, 42, 61, 1);
  margin-bottom: 10px;
  line-height: 20px;
}

.notificationDescription {
  width: 289px;
  font-size: 12px;
  color: rgba(115, 115, 115, 1);
  line-height: 16px;
  font-weight: 400;
  
}

.dateTimeContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // display: grid;
  // grid-template-columns: 1fr 1fr auto;
  gap: 10px;
  padding: 20px;
  // background: #f5f5f5;
  margin-bottom: 16px;
  border-radius: 8px;
}

.datePicker {
  width: 240px;
  height: 40px;
  border-radius: 4px;
}

.timePicker {
  width: 140px;
  height: 40px;
  border-radius: 4px;
}
.timepickerPopup{
  :global(.ant-picker-time-panel-column::after){
    display: block;
    height: 5px;
    content: '';
  }
}

.dialySummary {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .checkbox {
      :global(.ant-checkbox-input) {
          display: none;
      }
  }
 
  
}
.daterangTitle {
  font-weight: 400;
  font-size: 14px;
  display: rgba(115, 115, 115, 1);
  margin-bottom: 10px;
  line-height: 20px;
}
.resetButton {
  font-size: 14px;
  font-weight: 500;
  color: rgba(18, 113, 166, 1);
  padding-left: 0;
}
.resetButton:disabled {
  color:rgba(18, 113, 166, 1);
}
.resetButton:hover:disabled {
  color:rgb(47, 137, 185);
}

.errorText {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}
