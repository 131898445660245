.wizardModel {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;

  form {
    width: 550px;
    .formInput {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-bottom: 30px;

      label {
        font-size: 14px;
        font-weight: 400;
        color: #737373;
        font-family: "Inter", sans-serif;
      }

      .input {
        border: 1px solid #e5e5e5;
        padding: 12px;
        font-size: 14px;
        font-weight: 400;
        width: 100%;

        &::placeholder {
          color: #a3a3a3;
        }
      }

      textarea {
        border: 1px solid #e5e5e5;
        padding: 12px;
        font-size: 14px;
        font-weight: 400;
        resize: none;
        width: 100%;
        height: 130px;

        &::placeholder {
          color: #a3a3a3;
        }
      }

      .goal_input_wrapper {
        position: relative;
        flex: 1;
        width: 100%;

        .goal_input {
          width: 100%;
          border-radius: 4px;
          border: 1px solid #e5e5e5;
          background-color: #ffffff;
          margin: 0;
          padding: 0;
          padding: 10px;
          font-size: 14px;
          color: #a3a3a3;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          user-select: none;
          gap: 10px;

          .goal_input_selected {
            width: 100%;

            h3 {
              margin: 0;
              padding: 0;
              color: #171717;
              font-weight: 400;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              white-space: normal;
              word-break: break-word;
              -webkit-line-clamp: 1;
            }
          }
        }
      }
    }
  }

  .wizardFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 12px 0px;

    button {
      padding: 8px 20px;
      border: none;
      outline: none;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      border: 1px solid transparent;
      font-family: "Inter", sans-serif;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }

    button:nth-child(1) {
      background-color: transparent;
      color: #1271a6;
      border-color: #1271a6;
    }

    button:nth-child(2) {
      background-color: #1271a6;
      color: #fff;
    }
  }
}

.primaryButton {
  background-color: #1271a6;
  color: #ffffff;
  padding: 8px 35px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  outline: none;
}

.loader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
  height: 700px;
}

.gradientText {
  font-size: 36px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  letter-spacing: -3%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
  user-select: none;
  background: linear-gradient(to right, #000000 40%, #32bbdd 65%, #7fb3c0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.errorText {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  font-family: "Poppins", sans-serif;
  letter-spacing: -3%;
  margin: 0;
  padding: 0;
  margin-top: 32px;
  margin-bottom: 18px;
}

.notYou {
  font-size: 14px;
  font-weight: 400;
  color: #737373;
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 60px;
}

.poweredText {
  font-size: 14px;
  font-weight: 400;
  color: #737373;
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  margin-top: -20px;

  span {
    color: #1271a6;
  }
}

.skip {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 14px;
  font-weight: 500;
  color: #1271a6;
  cursor: pointer;
}
