.bg {
  display: flex;
  justify-content: center;
  //align-items: start;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 1001;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: scroll;
  //display: none;

  .wrapper {
    width: 500px;
    height: 635px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    background: white;
    position: absolute;
    margin: 0 auto;
    top: 10vh;
    //left: calc(50vw - 285px);

    //overflow-x: hidden;
    padding-bottom: 20px;
    //box-shadow: 0 1px 3px 1px #c9c9c9;
    .header {
      width: 100%;
      height: 73px;
      background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid #e6e7eb;

      span {
        font-family: "Inter", serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
      }

      .close {
        width: 36px;
        height: 36px;
        border-radius: 4px;
        border: solid 1px white;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 0;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 26px 30px;
      color: black;
      position: relative;

      img {
        width: 440px;
        height: 440px;
        object-fit: cover;
      }
    }

    .loader {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 30px;
      padding: 0 30px;
      width: 100%;

      button {
        width: 88px;
        height: 40px;
        font-family: "Poppins", serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        border: none;
        line-height: 16px;
        border-radius: 4px;
        padding: 12px 16px;
        cursor: pointer;
      }

      .delete {
        color: #1f85cf;
        background: white;
        border: 1px solid silver;

        &:not(:disabled):hover {
          box-shadow: 0 0 2px 2px silver;
        }

        &:disabled {
          background: #e6e7eb;
          color: #8f8f8f;
          cursor: default;
        }
      }

      .upload {
        color: #fff;
        background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
        border-radius: 4px;
        margin-left: 20px;

        &:not(:disabled):hover {
          box-shadow: 0 0 2px 2px silver;
        }

        &:disabled {
          background: #e6e7eb;
          color: #8f8f8f;
          cursor: default;
        }
      }
    }
  }
}
.warning {
  color: red;
  position: absolute;
  top: 110px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .centred {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 1199px) {
  .wrapper {
    width: 90vw;
    //max-width: 500px;
    height: 60vw;
    max-height: 635px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    background: white;
    position: absolute;
    top: 10vh;
    img {
      min-width: 200px;
      min-height: 200px;
      width: 30vw;
      height: 30vw;
      max-height: 440px;
      max-width: 440px;
    }
    //left: 5vw
  }
}
@media (max-width: 767px) {
  .wrapper {
    max-width: 90vw;
    height: 461px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    background: white;
    position: absolute;
    top: 10vh;
    img {
      max-width: 312vw;
      max-height: 312vw;
    }
    //left: 5vw
  }
}
