.img {
  width: 440px;
  height: 440px;
  margin: 0;
  padding: 0;
  position: relative;
  object-fit: cover;
  border-spacing: 5px;
  line-height: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered {
  margin-right: 48%;
  transform: scale(2);
}

.placeholder {
  width: 294px;
  height: 294px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .icon {
    margin-bottom: 10px;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1689CA;
    margin-bottom: 0;
    text-align: center;
  }

  .imageWarning {
    color:#ff0000;
    font-size: 10px;
    margin-top: 10px;
  }
}

@media (max-width: 1199px) {
  .img {
    min-width: 200px;
    min-height: 200px;
    width: 30vw;
    height: 30vw;
    max-height: 440px;
    max-width: 440px;

    .placeholder {
      min-width: 150px;
      min-height: 150px;
      width: 30vw;
      height: 30vw;
      max-height: 240px;
      max-width: 240px;
    }
  }
}

@media (max-width: 767px) {
  .img {
    width: 312px;
    height: 312px;

    .placeholder {
      width: 294px;
      height: 294px;
    }
  }
}
