.planHeader {
  width: 100%;
  background-color: #fff;
  margin-bottom: 20px;
  position: relative;
  box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);

  .planHeader_details {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .planHeader_details_left {
      width: 530px !important;

      .title {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 8px;

        h2 {
          margin: 0;
          padding: 0;
          font-size: 20px;
          font-weight: 600;
          color: #06203a;
          font-family: "Poppins", sans-serif;
          word-break: break-all;
          max-width: 530px !important;
        }

        .settings {
          background-color: transparent;
          border: none;
          padding: 0;
          width: 20px;
          height: 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .deadline {
        font-size: 14px;
        font-weight: 400;
        color: #4c545b;
        font-family: "Inter";
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 20px;

        span {
          font-weight: 600;
        }
      }

      .objectiveButtons {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 4px;

        button {
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          color: #262626;
          font-family: "Inter";
          background-color: transparent;
          border: none;
          outline: none;
          margin: 0;
          padding: 0;
        }

        .active {
          color: #71aaca;
          font-weight: 500;
        }
      }

      .objectiveTextarea {
        max-width: 530px;

        textarea {
          width: 100%;
          max-height: 50px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          resize: none;
          border: none;
          font-family: "Inter";

          &:not(:read-only) {
            padding: 4px;
            border: 1px solid #e5e5e5;
          }
        }
      }
    }

    .planHeader_details_right {
      position: relative;
      background-color: #fafafa;
      padding: 6px 12px;
      border: 1px solid #f5f5f5;
      width: 590px;
      display: flex;
      justify-content: space-between;
      gap: 30px;
      min-height: 150px;

      .goalitem {
        .goalitem_content {
          max-width: 330px;
          margin-bottom: 15px;

          h4,
          h2 {
            font-size: 14px;
            font-weight: 600;
            font-family: "Inter";
            margin: 0;
            padding: 0;
            margin-bottom: 10px;
            padding-bottom: 15px;
          }

          h3 {
            color: #000000;
          }

          h2 {
            color: #737373;
          }

          h6 {
            color: #6d7784;
            font-size: 11px;
            font-weight: 500;
            font-family: "Inter";
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;
          }

          .goal_input_wrapper {
            flex: 1;
            width: 100%;
          }
        }

        .goalitem_metric {
          display: flex;
          align-items: center;
          gap: 20px;

          .goalitem_input {
            width: 36px;
            justify-content: center;
            align-items: center;
          }

          .goalitem_select {
            align-items: flex-start;
            justify-content: flex-start;

            label {
              margin-left: 1px;
            }
          }

          .goalitem_input,
          .goalitem_select {
            display: flex;
            flex-direction: column;

            label {
              font-size: 12px;
              font-weight: 400;
              font-family: "Inter";
              color: #262626;
              margin-bottom: 4px;
              // margin: 0 auto;
            }

            .goalitem_metric_input {
              background-color: #ffffff;
              width: 38px;
              height: 28px;
              padding: 4px;
              border: 1px solid #e5e5e5;
              border-radius: 4px;
              font-size: 14px;
              font-weight: 500;
              color: #a3a3a3;
            }
          }
        }
      }

      .progress {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 22px;
        // margin-top: 30px;
      }
    }
  }
}

.wizardHeader {
  width: 100%;
  padding: 30px 0px 12px 0px;
  border-bottom: 1px solid #cdcdcd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .wizardHeader_left {
    display: flex;
    align-items: center;
    gap: 15px;

    .wizardHeader_content {
      margin-right: 80px;

      h3 {
        margin: 0;
        padding: 0;
        color: #171717;
        font-weight: 600;
        font-family: "Inter";
        font-size: 17px;
        margin-bottom: 6px;
      }
      h4 {
        margin: 0;
        padding: 0;
        color: #737373;
        font-weight: 400;
        font-family: "Inter";
        font-size: 14px;
      }
    }

    .reloadIcon {
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: rotate(180deg);
      }
    }
  }

  .wizardHeader_right {
    margin-right: 220px;
    p {
      margin: 0;
      padding: 0;
      color: #171717;
      font-size: 13px;
      font-weight: 400;
      max-width: 400px;
      font-family: "Inter";
    }
  }
}

.wizardBody {
  width: 100%;
  background-color: #fff;
  margin-top: 20px;
  box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);

  .listHeader {
    margin-top: 20px;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #cdcdcd;

    display: flex;
    align-items: center;
    gap: 20px;
    padding: 12px 20px;
    margin: 0;

    li {
      color: #171717;
      font-size: 14px;
      font-weight: 600;
      font-family: "Inter";
      list-style: none;
      display: flex;
      width: 10%;
    }

    li:nth-child(1) {
      width: 45%;
      padding-left: 40px;
    }
  }

  .planList_category {
    background-color: #f4f9fb;
    padding: 22px 15px;

    .title {
      color: #171717;
      font-size: 14px;
      font-weight: 500;
      font-family: "Inter";
      margin: 0;
      padding: 0;
    }

    .tags {
      background-color: #1271a6;
      color: #fff;
      padding: 4px 8px;
      border-radius: 4px;
    }
  }

  .planList {
    background-color: #fff;
    padding: 14px 15px;

    .title {
      h2 {
        color: #404040;
        font-weight: 400;
      }
    }
  }

  .planList_category {
    .title {
      h2 {
        color: #171717;
        font-weight: 500;
      }
    }
  }

  .planList,
  .planList_category {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;

    &:hover {
      background-color: #f9f9f9;
    }

    input {
      margin-left: 5px;
      margin-right: 10px;
    }

    .title {
      width: 45%;
      display: flex;
      align-items: center;
      gap: 12px;

      h2 {
        font-family: "Inter";
        margin: 0;
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        white-space: nowrap;
        -webkit-box-orient: vertical;
        white-space: normal;
        word-break: break-word;
        -webkit-line-clamp: 1;
        transition: all 0.3s ease;
        font-size: 14px;

        &:hover {
          white-space: nowrap;
        }
      }

      .perioty {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        height: 24px;
        border-radius: 20px;
        padding: 2px 10px;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .weight {
      width: 10%;
      text-wrap: nowrap;
    }

    .measurement {
      width: 10%;
    }

    .duration {
      width: 10%;
    }

    .deadline {
      width: 10%;
      display: flex;
      align-items: center;
      color: #171717;
      font-size: 14px;
      font-weight: 400;
      gap: 24px;

      span {
        width: 50px;
      }
    }
  }
}

.model_footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 10px;
  padding: 16px 0px;

  button {
    border: none;
    outline: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: "Inter";

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  button:nth-child(1) {
    padding: 8px 20px;
    background-color: transparent;
    color: #1271a6;
    border: 1px solid #1271a6;
  }

  button:nth-child(2) {
    background-color: #1271a6;
    color: #fff;
    padding: 10px 45px;
  }
}

.loader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
  height: 700px;
}

.gradientText {
  font-size: 36px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  letter-spacing: -3%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
  user-select: none;
  background: linear-gradient(to right, #000000 40%, #32bbdd 65%, #7fb3c0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.poweredText {
  font-size: 14px;
  font-weight: 400;
  color: #737373;
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  margin-top: -20px;

  span {
    color: #1271a6;
  }
}
