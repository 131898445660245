.planHeader {
  width: 100%;
  background-color: #fff;
  margin-bottom: 20px;
  position: relative;

  .planHeader_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 10px 20px;
    border-bottom: 1px solid #c1cad1;

    .planHeader_title_text {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;

      .back {
        margin-top: -2px;
      }

      h3 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        color: #1271a6;
        font-family: "Inter";
      }
    }

    .planHeader_items {
      display: flex;
      align-items: center;
      gap: 24px;

      .action {
        color: #4c545b;
        font-size: 12px;
        font-weight: 600;
        text-decoration: none;
        text-transform: uppercase;
        font-family: "Inter";
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;
      }
    }
  }

  .planHeader_details {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .planHeader_details_left {
      width: 530px !important;

      .title {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 8px;

        h2 {
          margin: 0;
          padding: 0;
          font-size: 20px;
          font-weight: 600;
          color: #06203a;
          font-family: "Poppins", sans-serif;
          word-break: break-all;
          max-width: 530px !important;
        }

        .settings {
          background-color: transparent;
          border: none;
          padding: 0;
          width: 20px;
          height: 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .deadline {
        font-size: 14px;
        font-weight: 400;
        color: #4c545b;
        font-family: "Inter";
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 20px;

        span {
          font-weight: 600;
        }
      }

      .objectiveButtons {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 4px;

        button {
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          color: #262626;
          font-family: "Inter";
          background-color: transparent;
          border: none;
          outline: none;
          margin: 0;
          padding: 0;
        }

        .active {
          color: #71aaca;
          font-weight: 500;
        }
      }

      .objectiveTextarea {
        max-width: 530px;

        textarea {
          width: 100%;
          max-height: 50px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          resize: none;
          border: none;
          font-family: "Inter";

          &:not(:read-only) {
            padding: 4px;
            border: 1px solid #e5e5e5;
          }
        }
      }
    }

    .planHeader_details_right {
      position: relative;
      background-color: #fafafa;
      padding: 6px 12px;
      border: 1px solid #f5f5f5;
      width: 590px;
      display: flex;
      justify-content: space-between;
      gap: 30px;
      min-height: 150px;

      .goalitem {
        .goalitem_content {
          max-width: 330px;
          margin-bottom: 15px;

          h4,
          h2 {
            font-size: 14px;
            font-weight: 600;
            font-family: "Inter";
            margin: 0;
            padding: 0;
            margin-bottom: 10px;
            padding-bottom: 15px;
          }

          h3 {
            color: #000000;
          }

          h2 {
            color: #737373;
          }

          h6 {
            color: #6d7784;
            font-size: 11px;
            font-weight: 500;
            font-family: "Inter";
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;
          }

          .goal_input_wrapper {
            flex: 1;
            width: 100%;
          }
        }

        .goalitem_metric {
          display: flex;
          align-items: center;
          gap: 20px;

          .goalitem_input {
            width: 36px;
            justify-content: center;
            align-items: center;
          }

          .goalitem_select {
            align-items: flex-start;
            justify-content: flex-start;

            label {
              margin-left: 1px;
            }
          }

          .goalitem_input,
          .goalitem_select {
            display: flex;
            flex-direction: column;

            label {
              font-size: 12px;
              font-weight: 400;
              font-family: "Inter";
              color: #262626;
              margin-bottom: 4px;
              // margin: 0 auto;
            }

            .goalitem_metric_input {
              background-color: #ffffff;
              width: 38px;
              height: 28px;
              padding: 4px;
              border: 1px solid #e5e5e5;
              border-radius: 4px;
              font-size: 14px;
              font-weight: 500;
              color: #a3a3a3;
            }
          }
        }
      }

      .progress {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 22px;
        // margin-top: 30px;
      }
    }
  }

  .planHeader_actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding-top: 4px;
    padding: 0px 30px;
    border-top: 1px solid #cdcdcd;
    border-bottom: 1px solid #cdcdcd;

    .navWrapper {
      display: flex;
      align-items: center;

      a {
        padding: 12px 24px;
        font-size: 14px;
        font-weight: 500;
        font-family: "Inter";
        text-decoration: none;
        color: #737373;

        &.active {
          font-weight: 600;
          color: #ff9900;
          border-bottom: 3px solid #ff9900;
        }

        &.disabled {
          pointer-events: none;
        }
      }
    }

    .planHeader_options {
      display: flex;
      align-items: center;
      gap: 20px;

      .filterIcon {
        font-size: 13px;
        font-weight: 500;
        color: #4c545b;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        background-color: orange;
        border-radius: 20px;
        padding: 4px 8px;
      }

      .button,
      .view {
        padding: 0px 8px;
        font-size: 12px;
        font-weight: 500;
        color: #4c545b;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        cursor: pointer;
      }

      .searchWrapper {
        width: 200px;
        position: relative;
        background-color: #f5f5f5;
        border: 1px solid #e5e5e5;
        padding: 8px 12px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 35px;

        input {
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          font-family: "Inter";
          color: #737373;
          border: none;
          outline: none;
          background: transparent;
        }

        .icon {
          right: 10px;
          position: absolute;
        }
      }

      .filterButton {
        color: #4c545b;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 7px 12px;
        border-radius: 20px;
        user-select: none;
      }

      .filterButtonActive {
        background-color: #418db8;
        color: #fff;
      }

      .expandAllNotes {
        background-color: #f5f5f5;
        color: #4c545b;
        border: none;
        padding: 0;
        padding: 6px 12px;
        border-radius: 2px;
        font-family: "Inter";
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        border: 1px solid #e5e5e5;
      }
    }
  }
}

.connectModel {
  .modelHeader {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    h3 {
      font-size: 18px;
      font-weight: 600;
      color: #171717;
      margin: 0;
      padding: 0;
    }
  }

  .main {
    padding: 0px 24px;

    .sureText {
      font-size: 14px;
      font-weight: 400;
      color: #171717;
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px solid #edeef2;
    }

    .goal_content {
      label {
        font-size: 14px;
        font-weight: 500;
        color: #737373;
      }

      h4 {
        color: #171717;
        width: 90%;
        font-size: 14px;
        font-weight: 500;
        margin: 12px 0px;
      }
    }
  }

  .actions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 12px;
    padding: 16px 24px;
    background-color: #f5f5f5;
    margin-top: 16px;

    button {
      border: 1px solid #1271a6;
      border-radius: 2px;
      font-size: 13px;
      font-weight: 500;
      cursor: pointer;
      font-family: "Inter";
      padding: 8px 20px;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }

    button:nth-child(1) {
      background-color: transparent;
      color: #1271a6;
    }

    button:nth-child(2) {
      background-color: #1271a6;
      color: #fff;
    }
  }
}

.menuItem,
.deleteItem {
  display: flex;
  align-items: center;
  gap: 10px;
  color: "#1B2A3D";
  padding: 4px 0px;
}
.menuItem {
  &:hover {
    color: #1271a6;
  }
}
.deleteItem {
  &:hover {
    color: red;
  }
}

.filterContainer {
  width: 260px;

  .filterHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 16px;

    h3 {
      font-size: 14px;
      font-weight: 600;
      color: #1b2a3d;
      margin: 0;
      padding: 0;
    }
  }

  .filterContain {
    padding: 12px 16px 20px;
    max-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;

    .filterSelect_box {
      display: flex;
      flex-direction: column;
      gap: 6px;

      label {
        font-size: 14px;
        font-weight: 500;
        color: #737373;
        margin: 0;
        padding: 0;
        font-family: "Inter";
      }

      .filterSelect {
        width: 100%;
        border: 1px solid #e5e5e5;
        border-radius: 4px !important;
      }
    }

    .filterSelected_box {
      margin-bottom: 12px;
      width: 100%;
      border: 1px solid #f5f5f5;

      .filterSelected_header {
        background-color: #f5f5f5;
        padding: 12px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          font-size: 14px;
          font-weight: 500;
          color: #1271a6;
          margin: 0;
          padding: 0;
          font-family: "Inter";
        }
      }

      .filterSelected_content {
        width: 100%;
        background-color: #fafafa;
        padding: 12px;

        .filterSelect {
          width: 100%;
          border: 1px solid #e5e5e5;
          border-radius: 4px !important;
        }
      }
    }

    .AddFiler {
      display: flex;
      align-items: center;
      gap: 12px;
      color: #1271a6;
      font-size: 14px;
      font-weight: 500;
      padding: 8px 0px;
      cursor: pointer;
    }
  }

  .actionButton {
    padding: 20px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    border-top: 1px solid #edeef2;

    button {
      border-radius: 2px;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
      font-family: "Inter";
      padding: 8px 12px;
      border-radius: 3px;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }

    button:nth-child(1) {
      background-color: #ecf3f6;
      color: #1271a6;
      border: 1px solid #ecf3f6;
    }

    button:nth-child(2) {
      background-color: #1271a6;
      border: 1px solid #1271a6;
      color: #fff;
    }
  }
}

.wizardHeader {
  width: 100%;
  padding: 30px 0px 12px 0px;
  border-bottom: 1px solid #cdcdcd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .wizardHeader_left {
    display: flex;
    align-items: center;
    gap: 15px;

    .wizardHeader_content {
      margin-right: 100px;

      h3 {
        margin: 0;
        padding: 0;
        color: #171717;
        font-weight: 600;
        font-family: "Inter";
        font-size: 17px;
        margin-bottom: 6px;
      }
      h4 {
        margin: 0;
        padding: 0;
        color: #737373;
        font-weight: 400;
        font-family: "Inter";
        font-size: 14px;
      }
    }
  }

  .wizardHeader_right {
    margin-right: 190px;
    p {
      margin: 0;
      padding: 0;
      color: #171717;
      font-size: 13px;
      font-weight: 400;
      max-width: 400px;
      font-family: "Inter";
    }
  }
}
