.header {
  padding: 20px 0;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: rgba(18, 113, 166, 1);
}

.tabsContainer {
  display: flex;
  gap: 30px;
  margin-top: 10px;
  border-bottom: 2px solid #e5e5e5;
}

.tab {
  font-size: 14px;
  font-weight: 6000;
  color: #8c8c8c;
  padding: 10px 0;
  cursor: pointer;
  position: relative;

  &:hover {
    color: rgba(255, 153, 0, 1);
  }
}

.activeTab {
  color: rgba(255, 153, 0, 1);
  font-weight: 600;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background:  rgba(255, 153, 0, 1);
    bottom: -2px;
    left: 0;
  }
}

.contentContainer {
  padding: 20px;
  margin-top: 20px;
  background: #f9f9f9;
  border-radius: 8px;
}
