.ariaModel {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ariaModel_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 24px;
    border-bottom: 1px solid #cdcdcd;

    .ariaModel_header_left {
      display: flex;
      align-items: center;
      gap: 15px;

      h1 {
        margin: 0;
        padding: 0;
        color: #171717;
        font-size: 16px;
        font-weight: 600;
        font-family: "Inter", sans-serif;
      }

      svg {
        width: 40px;
        height: 40px;
      }
    }

    .closeBox {
      cursor: pointer;
    }
  }

  .ariaModel_body {
    width: 100%;

    .listHeader {
      width: 100%;
      background-color: #fff;
      border-bottom: 1px solid #cdcdcd;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 12px 20px;
      margin: 0;

      li {
        color: #171717;
        font-size: 14px;
        font-weight: 600;
        font-family: "Inter";
        list-style: none;
        display: flex;
        width: 10%;
      }

      li:nth-child(1) {
        width: 40%;
        padding-left: 40px;
      }
    }

    .planList_container {
      margin-bottom: 35px;
    }

    .planList_category {
      background-color: #f4f9fb;
      padding: 22px 15px;

      .title {
        color: #171717;
        font-size: 14px;
        font-weight: 500;
        font-family: "Inter";
      }

      .tags {
        background-color: #1271a6;
        color: #fff;
        padding: 4px 8px;
        border-radius: 4px;
      }
    }

    .planList {
      background-color: #fff;
      padding: 14px 15px;

      .title {
        color: #404040;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .planList,
    .planList_category {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;

      &:hover {
        background-color: #f9f9f9;
      }

      input {
        margin-left: 5px;
        margin-right: 10px;
      }

      .title {
        width: 40%;
        display: flex;
        align-items: center;
        gap: 12px;
        flex: 1;

        h2 {
          font-family: "Inter";
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          white-space: nowrap;
          -webkit-box-orient: vertical;
          white-space: normal;
          word-break: break-word;
          -webkit-line-clamp: 1;
          transition: all 0.3s ease;
          color: #404040;
          font-size: 14px;
          font-weight: 400;

          &:hover {
            white-space: nowrap;
          }
        }

        .perioty {
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px;
          height: 24px;
          border-radius: 20px;
          padding: 2px 10px;
          font-size: 12px;
          font-weight: 400;
        }
      }

      .weight {
        width: 10%;
        text-wrap: nowrap;
      }

      .measurement {
        width: 10%;
      }

      .duration {
        width: 10%;
      }

      .deadline {
        width: 10%;
        display: flex;
        align-items: center;
        color: #171717;
        font-size: 14px;
        font-weight: 400;
        gap: 24px;
      }
    }
  }

  .ariaModel_suggestion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 34px 24px;

    h4 {
      width: 70%;
      margin: 0;
      padding: 0;
      color: #737373;
      font-size: 14px;
      font-weight: 400;
      font-family: "Inter", sans-serif;
    }

    .ariaModel_suggestion_actions {
      display: flex;
      align-items: center;
      gap: 20px;

      span {
        color: #06203a;
        font-size: 14px;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
      }

      button {
        width: 28px;
        height: 28px;
        border: none;
        cursor: pointer;
        background-color: #ecf3f6;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        // color: ;
      }
    }
  }

  .ariaModel_footer {
    width: 100%;
    padding: 16px 24px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .regenerate {
      display: flex;
      align-items: center;
      gap: 6px;
      color: #a3a3a3;
      font-size: 14px;
      font-weight: 500;
      font-family: "Inter", sans-serif;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    .ariaModel_footer_actions {
      display: flex;
      align-items: center;
      gap: 12px;

      button {
        border: none;
        outline: none;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 6px;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }

      button:nth-child(1) {
        padding: 8px 20px;
        background-color: transparent;
        color: #1271a6;
        border: 1px solid #1271a6;
      }

      button:nth-child(2) {
        background-color: #1271a6;
        color: #fff;
        padding: 8px 24px;
      }
    }
  }
}

.tryAgainButton {
  background-color: transparent;
  color: #000000;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
  border: transparent;
  font-family: "Inter", sans-serif;
  cursor: pointer;
}

.primaryButton {
  background-color: #1271a6;
  color: #ffffff;
  padding: 8px 35px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  outline: none;
}

.loader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
  height: 700px;
}

.gradientText {
  font-size: 36px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  letter-spacing: -3%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
  user-select: none;
  background: linear-gradient(to right, #000000 40%, #32bbdd 65%, #7fb3c0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.errorText {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  font-family: "Poppins", sans-serif;
  letter-spacing: -3%;
  margin: 0;
  padding: 0;
  margin-top: 32px;
  margin-bottom: 20px;
}

.notYou {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 80px;
}

.poweredText {
  font-size: 14px;
  font-weight: 400;
  color: #737373;
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  margin-top: -20px;

  span {
    color: #1271a6;
  }
}

.skip {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 14px;
  font-weight: 500;
  color: #1271a6;
  cursor: pointer;
}
