* {
  box-sizing: border-box;
}

.tableContainer {
  position: relative;
  padding: 10px 20px 0px;

  @media (max-width: 1300px) {
    padding: 6px 20px 0px;
  }

  .addOptionContainer {
    width: 18rem;
    display: flex;
    justify-content: space-between;
    transform: translateY(-10px);
    user-select: none;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-right: 10px;
    }

    button {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      background-color: #1271a6;
      border: none;
      border-radius: 5px;
      padding: 10px 16px;
      user-select: none;

      @media (max-width: 1300px) {
        height: 36px;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .arrow {
      position: absolute;
      top: 4px;
      right: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1px;
      height: 100%;
    }

    input {
      width: 77px;
      height: 44px;
      background-color: white;
      border: none;
      border-radius: 6px;
      padding: 10px;
      border: 1px solid #e5e5e5;

      @media (max-width: 1300px) {
        width: 62px;
        height: 36px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}

.checkboxNavbar {
  display: flex;
  padding-left: 1.25rem;
  padding-bottom: 0.75rem;

  .checkedAll {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #0c5b94;
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: 1300px) {
      line-height: 16px;
    }
  }

  .shortlistLink {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration: underline;
    color: #116595;
    padding: 0;
    margin: 0;
    margin-left: 1.5rem;
    transform: translateY(5px);
    cursor: pointer;
    text-transform: uppercase;

    @media (max-width: 1300px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.paddingTop {
  padding-top: 40px;
}

.tableWrapper {
  // border-radius: 4px;
  min-height: 240px;
  // overflow: hidden;
  // height: calc(100vh - 50vh);

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: #bdc3c7;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darken(#bdc3c7, 20%);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 50px;
  }

  .table {
    display: flex;
    position: relative;
    overflow: hidden;
    filter: drop-shadow(2px 0px 7px rgba(0, 0, 0, 0.25));

    .tableMainWrapper {
      position: relative;
      z-index: 100;
    }

    .tableMainHead {
      display: flex;
    }

    .tableMain {
      display: flex;
      border-radius: 4px 0 0 0;
      overflow-y: scroll;

      // DM?
      max-height: calc(100vh - 420px);

      // AT
      &.at {
        max-height: calc(100vh - 310px);

        &.readonly {
          max-height: calc(100vh - 310px - 82px);
        }
      }

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      &::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 100%;
        right: 0;
        top: 0;
        z-index: 0;
        pointer-events: none;
        display: block;
        margin-right: 0px;
        box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
      }
    }

    .tableMainContent {
      position: relative;
      z-index: 2;

      .row {
        display: flex;
      }
    }

    select {
      option {
        background-color: white;
      }
    }

    .column {
      &.columnbody {
        display: flex;
      }

      .boxTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80px;
        height: 40px;
        background: #1271a6;
        padding: 6px 4px;
        outline: none;
        border: none;
        border-right: 1px solid white;

        span {
          flex: 1;
          color: white;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 14px;
        }

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .box1Title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80px;
        height: 40px;
        background: #1271a6;
        padding: 6px 4px;
        outline: none;
        border: none;
        border-right: 1px solid white;

        span {
          flex: 1;
          color: white;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 14px;
        }

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .box1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        min-height: 74px;
        height: 100%;
        background: white;
        border-right: 1px solid #d5e6f6;
        border-bottom: 1px solid #d5e6f6;
        // background: linear-gradient(0deg, #f3f8fb, #f3f8fb), #ffffff;

        &.high {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #feab3d;
          background: linear-gradient(0deg,
              rgba(254, 171, 61, 0.1),
              rgba(254, 171, 61, 0.1)),
            #ffffff;
        }

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          width: 75%;
          text-align: center;
        }

        .chart {
          transform: translateY(8px);
        }

        .prosConsCount {
          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: #021426;
            text-align: center;
            display: flex;
            justify-content: center;
            width: 100%;
          }
        }

        .proConstStatusBar {
          width: 100%;
          display: flex;
          align-items: center;

          :first-child {
            background-color: #7eaa77;
            padding: 1.5px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            margin-right: 0.75px;
          }

          :last-child {
            margin-left: 0.75px;
            background-color: #f06060;
            padding: 1.5px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      .box2Title {
        display: flex;
        justify-content: space-between;
        padding: 8px 0 8px 16px;
        width: 490px;
        height: 40px;
        background: #1271a6;
        border-right: 1px solid white;

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: white;
        }

        .addOptionsButton {
          padding: 0;
          padding-left: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          outline: none;
          border: none;
          background: transparent;
          font-family: "Inter";
          font-style: normal;
          font-size: 12px;
          line-height: 24px;
          font-weight: 600;
          color: #feab3d;

          svg {
            margin-right: 4px;
            width: 10px;
          }
        }

        .popover {
          width: 24px;
          height: 24px;
          color: #ffffff;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }

      .box2 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 490px;
        min-height: 74px;
        height: 100%;
        background: white;
        border-right: 1px solid #d5e6f6;
        border-bottom: 1px solid #d5e6f6;

        &.high {
          background: linear-gradient(0deg,
              rgba(254, 171, 61, 0.1),
              rgba(254, 171, 61, 0.1)),
            #ffffff;
        }
      }

      .box3Title {
        display: flex;
        justify-content: space-between;
        padding: 8px 16px;
        height: 40px;
        background: #1271a6;
        border-right: 1px solid white;
        font-size: 14px;

        .activeBtn {
          background: #116595 !important;
        }

        .descLong {
          max-width: 165px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: white;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-right: 6px;
        }

        .descShort {
          width: 60px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: white;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-right: 6px;
        }

        .optionWeight {
          width: 30px;
          height: auto;
          border-radius: 4px;
          background-color: #418db8;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          color: #fafafa;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 2px;
          margin-left: auto;
        }

        .infoPopover {
          display: flex;
          margin-left: auto;

          svg {
            opacity: 0.7;
            transform: translateX(6px);
          }
        }

        .popover {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          color: white;
          font-weight: 600;
          font-size: 20px;
          transform: translateX(10px);
          cursor: pointer;
        }
      }

      .box3 {
        padding: 8px 16px;
        min-height: 74px;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        border-bottom: 1px solid #d5e6f6;

        &.high {
          background: linear-gradient(0deg,
              rgba(254, 171, 61, 0.1),
              rgba(254, 171, 61, 0.1)),
            #ffffff;
        }

        &::after {
          content: "";
          height: 100%;
          display: inline-block;
          vertical-align: middle;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          max-height: 100%;
          max-width: 100%;
          margin-bottom: 0;
          white-space: pre-wrap;
          word-break: break-word;
        }

        button {
          font-family: "Inter";
          position: absolute;
          right: 0;
          bottom: 0;
          padding: 0;
          color: #418db8;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 11px;
          line-height: 19px;
          background: #fff;
          border: none;
          padding: 6.5px;
          margin-bottom: 3px;
          display: none;
          opacity: 0;

          &.active {
            display: block;
          }
        }

        &:not(.expand) p {
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          display: -webkit-inline-box;
          vertical-align: middle;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        &:hover button {
          opacity: 1;
        }
      }
    }
  }
}

.infoPopoverContent {
  background-color: #fff;
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.4));
  font-family: "Inter";
  padding: 4px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  max-width: 380px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
}

.boxScore {
  display: flex;
  margin-left: auto;
  align-items: center;
  width: 52px;
  min-height: 74px;
  height: 100%;
  border-bottom: 1px solid #d5e6f6;
  border-right: 1px solid #d5e6f6;
  justify-content: flex-start;

  &.full {
    width: 154px !important;
    justify-content: center;
  }

  &.high {
    background: linear-gradient(0deg,
        rgba(254, 171, 61, 0.1),
        rgba(254, 171, 61, 0.1)),
      #ffffff;
  }
}

.body {
  display: block;
  overflow: hidden;

  :global(.ant-layout-content) {
    overflow: hidden;
  }

  .criteriasHeader {
    display: flex;
    position: absolute;
    z-index: 1;

    .column {
      margin-top: 0;

      &.criteria {
        width: 295px;

        &.short {
          width: 154px;
        }
      }
    }
  }

  .criteriasBody {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
  }

  .criteriasWrapperMain {
    display: flex;
    overflow-x: scroll;
    overflow-y: scroll;

    // DM
    max-height: calc(100vh - 380px);

    // AT
    &.at {
      max-height: calc(100vh - 270px);

      &.readonly {
        max-height: calc(100vh - 270px - 82px);
      }
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .criteriasContent {
    display: flex;
    flex-direction: column;
  }

  .criteriasWrapper {
    display: flex;
  }

  .criteriaColumn {
    display: flex;
    flex-direction: row;

    .rowContainer {
      display: flex;
      z-index: 0;
      width: 100%;
      min-height: 74px;
      border: 1px solid #e6e7eb;
      border-top: none;
      background-color: white;
    }
  }

  .columnWrapper {
    display: flex;

    .column {
      display: flex;
      justify-content: space-between;
      background-color: #fff;

      &:hover {
        background: #f0f5f7;
      }
    }
  }

  .customScroll {
    &.horizontal {
      overflow-x: auto;
      margin-top: 12px;

      span {
        display: block;
        height: 1px;
      }

      @media (max-width: 1300px) {
        margin-top: 6px;
      }
    }

    &.vertical {
      position: absolute;
      overflow-y: scroll;
      top: 0;
      right: 0;
      width: 10px;
      margin-right: 4px;
      margin-top: 50px;
      // scrollbar-width: none;

      // DM
      height: calc(100% - 118px);

      // AT
      &.at {
        height: calc(100% - 40px);

        &.readonly {
          height: calc(100% - 40px - 82px);
        }
      }

      span {
        display: block;
        width: 1px;
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background: darken(#bdc3c7, 20%);
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: darken(#bdc3c7, 20%);
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 50px;
    }

    &::-webkit-scrollbar-corner {
      background: #d0ebff;
    }
  }
}
