.dropdown {
  width: 100%;

  :global(.ant-select-selector) {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &.customArrow {
    :global(.ant-select-arrow) {
      background-image: url("./arrow.svg");
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: center;
      width: 20px;
      height: 20px;
      right: 10px;
      margin-top: -9px;

      span {
        display: none;
      }
    }
  }

  :global(.ant-select-selection-placeholder),
  :global(.ant-select-selection-item) {
    color: #404040;
    font-family: Inter;
    font-size: 14px;
    line-height: 28px !important;
    text-align: right;
  }

  :global(.ant-select-selection-search-input) {
    border-radius: 0;
  }

  &.duration {
    :global(.ant-select-selector) {
      padding: 0;
    }

    :global(.ant-select-arrow) {
      display: none;
    }

    :global(.ant-select-selection-item) {
      padding-right: 0;
      text-align: center;
    }
  }

  &.weight {
    width: 60px;

    :global(.ant-select-arrow) {
      margin-right: -5px;
    }
  }

  &.person {
    :global(.ant-select-selector) {
      padding: 0;
    }

    :global(.ant-select-arrow) {
      display: none;
    }

    :global(.ant-select-selection-item) {
      padding-right: 0;
      padding-top: 1px;
      text-align: center;
      background: #b9c9da;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: 0 auto;
      text-transform: uppercase;
      color: #fff;
      font-size: 12px;
      flex: initial;
      cursor: pointer;
      transition: 0.3s ease;
      overflow: hidden;
    }

    &:not(:global(.ant-select-open)) {
      :global(.ant-select-selection-item) {
        transform: translateX(0px) scale(1);
      }
    }

    &:global(.ant-select-open) {
      :global(.ant-select-selection-item) {
        transform: translateX(-40px) scale(0);
      }
    }
  }
}

.selected {
  background-color: #418db8 !important;
  color: #fff !important;
}

.weightSelect {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.datePicker_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .datepicker {
    font-size: 14px !important;
    padding: 0;
    border: none;
    background: none !important;
    box-shadow: none !important;

    :global(.ant-picker-input input) {
      width: 70px;
    }
  }

  .closeIcon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
  }

  &:hover {
    .closeIcon {
      visibility: visible;
      opacity: 1;
    }
  }
}
