.bodyWrapper {
  padding: 10px 20px 85px;

  .body {
    display: flex;
    padding: 24px 0 24px 24px;
    width: 100%;
    min-height: 60vh;
    background: white;
    margin-top: 30px;
    border-radius: 4px;

    .criteria {
      width: 17%;
      min-width: 150px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 24px;
      overflow: hidden;
      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        height: 80px;
        padding-bottom: 24px;
        border-bottom: 1px solid #e6e7eb;
        width: 100%;
        overflow: hidden;
        word-break: break-all;
      }

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        height: 80px;
        padding: 24px 0;
        width: 100%;
      }

      .criterias {
        display: flex;
        flex-direction: column;
        width: 100%;

        .item {
          height: 56px;
          padding: 0 24px;
          width: 100%;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 56px;
          border-radius: 4px;
          margin: 12px 0;
          cursor: pointer;

          &.active {
            background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
            font-weight: 600;
            color: #fff;
          }

          &:not(.active):hover {
            background: #b6c4ee;
            filter: red;
          }
        }
      }
    }

    .tabsWrapper {
      // background-color: red !important;
      width: calc(100% - 280px);
      padding: 0px 10px 0px 24px;
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            .ant-tabs-tab-btn {
              color: red !important;
            }
          }
        }
      }
      .content {
        // width: calc(100% - 280px);
        width: 100%;
        // border-left: 1px solid #e6e7eb;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // padding: 0px 0px 0px 24px;

        .criteriaTab {
          display: flex;
          flex-direction: column;
          width: 100%;
          position: relative;

          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #112538;
          }

          .criterias {
            display: flex;
            flex-wrap: nowrap;
            width: 100%;
            overflow-x: scroll;
            // border-top: 1px solid #e6e7eb;
            margin: 15px 0 10px;
            gap: 15px;
            padding-right: 16px;

            &::before,
            &::after {
              content: "";
              width: 27px;
              height: 72px;
              position: absolute;
              margin-top: 5px;
              background: linear-gradient(
                270deg,
                #ffffff 0%,
                rgba(255, 255, 255, 0) 100%
              );
              pointer-events: none;
              opacity: 0;
              transition: opacity 0.3s;
            }

            &::before {
              left: 0;
              transform: rotate(-180deg);
            }

            &::after {
              right: 0;
            }

            &.scrollLeft {
              &::before {
                opacity: 1;
              }
            }

            &.scrollRight {
              &::after {
                opacity: 1;
              }
            }

            &::-webkit-scrollbar {
              width: 10px;
              height: 10px;
            }

            &::-webkit-scrollbar-button {
              width: 0px;
              height: 0px;
            }

            &::-webkit-scrollbar-thumb {
              background: #bdc3c7;
              border-radius: 50px;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: darken(#bdc3c7, 20%);
            }

            &::-webkit-scrollbar-track {
              background: transparent;
              border-radius: 50px;
            }

            .item {
              height: 56px;
              padding: 0 24px;
              width: auto;
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 56px;
              border-radius: 4px;
              margin: 12px 0px;
              cursor: pointer;
              border: 1px solid #d1d1d1;
              text-transform: capitalize;
              white-space: nowrap;
              user-select: none;

              &.active {
                background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
                font-weight: 800;
                color: #fff;
                border: none;
              }

              &:not(.active):hover {
                background: #efeded;
                color: #112538;
                // filter: grayscale(1);
              }
            }
          }
        }

        .wrapper {
          width: 100%;
          max-width: 1180px;
          padding-right: 24px;
        }

        .separator {
          padding-top: 15px;
          margin-bottom: 15px;
          border-top: 1px solid #e6e7eb;
          width: 100%;
          height: 1px;
        }

        .scoreContainer {
          width: 100%;
        }

        .scoreWrapper {
          display: flex;
          align-items: center;
        }

        .select {
          border-radius: 4px;
          margin: 15px 0;
          margin-right: 17px;
        }

        .selectInfo {
          background: #d1eeea;
          border-radius: 4px;
          padding: 8px 12px;
          position: relative;
          display: flex;
          flex-direction: column;

          &::before {
            content: "";
            position: absolute;
            top: 22px;
            left: 0;
            margin-left: -8px;
            width: 0;
            height: 0;
            border-width: 9px 8px 9px 0;
            border-color: transparent #d1eeea transparent transparent;
            border-style: solid;
          }

          span {
            font-family: "Inter";
            font-weight: 400;
            color: #112538;
            font-size: 16px;
            line-height: 24px;
          }
        }

        .contentTitle {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 20px;
          margin-bottom: 14px;
        }

        .q {
          font-family: "Inter";
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }

        .score {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          padding-right: 12px;
        }

        .factsWrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 24px;

          .heading_div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              padding: 12px 0;
            }
            .generateWithAI {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              margin-bottom: 5px;
              cursor: pointer;
              text-align: right;
              font-feature-settings: "tnum" on, "lnum" on;
              color: #1271a6;
              margin: 0;
            }
          }

          textarea {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            padding: 12px;
            min-width: 100%;
            width: 100%;

            border-radius: 4px;
            border: 1px solid #e3e5e9;
            width: 100%;
            min-height: 120px;

            &::placeholder {
              color: #e6e7eb;
            }
          }

          .factsFooter {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 10px;
            gap: 10px;
          }
        }

        .compareWrapper {
          width: 100%;

          .compareTitle {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 12px;
            color: #1b2a3d;
          }

          .compareTablet {
            display: flex;
            width: 100%;

            .column {
              display: flex;
              flex-direction: column;
              width: 50%;
              align-items: flex-start;
              border-radius: 4px;
              border: 1px solid #e3e5e9;

              .header {
                width: 100%;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                  font-family: "Inter";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  color: white;
                }
              }

              .prosWrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 0 16px;

                .br {
                  color: silver;
                  height: 1px;
                  background: 1px;
                  margin: 10px 16px;
                }
              }

              .pros {
                display: flex;
                width: 100%;
                justify-content: flex-start;
                margin: 10px 0 0;

                div {
                  font-family: "Inter";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: #858e99;
                }

                span {
                  font-family: "Inter";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  margin-left: 10px;
                }
              }

              .buttonAdd {
                margin: 15px 30px;
                display: flex;
                width: 170px;
                justify-content: space-evenly;
                align-items: center;
                cursor: pointer;

                span {
                  font-family: "Inter";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                }
              }

              .input {
                width: 90%;
                border-radius: 4px;
                border: 1px solid #e3e5e9;
                padding: 8px 12px;
                margin: 0 16px 20px;
              }
            }
          }
        }
      }
    }

    .details {
      width: 280px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 20px;
      border-right: 1px solid #e6e7eb;
      .optionName {
        // font-family: "Inter";
        // font-style: normal;
        // font-weight: 600;
        // font-size: 24px;
        // line-height: 28px;
        display: flex;
        align-items: center;
        color: #112538;
        margin: 0;
        padding: 0;
        width: 100%;

        p {
          color: #112538;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 111.111% */
          max-width: 200px;
          overflow: hidden;
          cursor: pointer;
        }
      }
      .detailHeading {
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        display: flex;
        align-items: center;
        color: #112538;
        padding-top: 20px;
      }

      .optionImgWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        // width: 100%;
        width: 250px;
        height: 250px;
        border-radius: 4px;

        .optionImg {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
          position: relative;
        }

        .editImg {
          width: 24px;
          min-width: unset;
          height: 24px;
          position: absolute;
          bottom: 7%;
          right: 18%;
          z-index: 10;
        }
      }

      .link {
        display: flex;
        flex-direction: column;
        word-wrap: break-word;
        hyphens: auto;
        word-break: break-all;
        margin-top: 12px;

        div {
          display: flex;
          justify-content: space-between;

          span {
            color: #1b2a3d;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            padding: 12px 0;
            width: 80%;
          }

          .edit {
            width: 24px;
            height: 24px;
            background: transparent;
            border: none;
            padding: 0;
            outline: none;
            margin-left: 12px;
            margin-top: -2px;
          }
        }
        a {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #1f85cf;
          text-decoration: none;
        }
      }

      .notes {
        display: flex;
        flex-direction: column;
        width: 100%;

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          padding: 12px 0;
        }

        textarea {
          color: #112538;
          width: 100%;
          border-radius: 4px;
          border: 1px solid #e3e5e9;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          padding: 8px 12px;
          min-height: 96px;
        }

        .notesFooter {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 10px;
          gap: 10px;
        }
      }

      .documents {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 8px;

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          padding: 12px 0;
        }

        button {
          color: #1271a6;
          background: transparent;
          border: none;
          font-weight: 500;
          text-decoration: underline;
          padding: 0;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .bodyTablet {
    padding: 16px;
    margin: 0;
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    .detailsTablet {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 16px;
      background: white;
      border-radius: 4px;
      margin-bottom: 12px;

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        padding: 12px 0;
      }

      .optionImgTablet {
        width: 192px;
        height: 192px;
        border-radius: 4px;
        margin-right: 20px;
      }

      .deteilContentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        word-wrap: break-word;
        hyphens: auto;
        word-break: break-all;
        width: 100%;
        padding: 0 0 0 0;

        span {
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #112538;
          padding: 0 0 8px;
        }

        .linkTablet {
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #1b2a3d;
        }
      }

      .notesTablet {
        display: flex;
        flex-direction: column;
        padding: 5px 0;
        width: 100%;

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          padding: 12px 0;
        }

        textarea {
          width: 100%;
          border-radius: 4px;
          border: 1px solid #e3e5e9;
          padding: 12px 12px;
          min-height: 66px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #112538;
        }
      }
    }

    .criteriasTablet {
      width: 100%;
      background: white;
      height: 88px;
      padding: 10px 15px 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom: 1px solid #c2c2c2;

      .titleTablet {
        font-family: "Inter", serif;
        color: #1b2a3d;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        margin: 0 0 10px;
        padding: 0 0 0 0;
      }

      .criteriasWrapperTablet {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          display: none;
        }

        .criteriaTablet {
          height: 36px;
          padding: 6px 25px;
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #112538;

          &.active {
            background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
            color: white;
            padding: 6px 45px;
            border-radius: 4px;
          }
        }
      }
    }

    .contentTablet {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 17px;
      background: white;

      .scoreWrapperTablet {
        display: flex;
        align-items: flex-start;
        width: 100%;
      }

      .selectTablet {
        border: 1px solid #e6e7eb;
        border-radius: 4px;
        margin: 15px 0px;
      }

      .selectInfoTablet {
        background: #d1eeea;
        border-radius: 4px;
        padding: 8px 12px;
        position: relative;
        display: flex;
        flex-direction: column;

        &::before {
          content: "";
          position: absolute;
          top: 22px;
          left: 0;
          margin-left: -8px;
          width: 0;
          height: 0;
          border-width: 9px 8px 9px 0;
          border-color: transparent #d1eeea transparent transparent;
          border-style: solid;
        }

        span {
          font-family: "Inter";
          font-weight: 400;
          color: #112538;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .contentTitleTablet {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 24px;
      }

      .qTablet {
        font-family: "Inter";
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }

      .scoreTablet {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 12px;
      }

      .factsWrapperTablet {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 24px;

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          padding: 12px 0;
        }

        textarea {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          padding: 12px;
          width: 100%;
          border-radius: 4px;
          border: 1px solid #e3e5e9;
          width: 100%;
          min-height: 120px;

          &::placeholder {
            color: #e6e7eb;
          }
        }
      }
    }
  }

  .item {
    width: 80px;

    div {
      border-radius: 4px;
      width: 100%;
      padding: 12px;
    }

    div:hover {
      background: #b6c4ee;
    }
  }
  .edit {
    width: 24px;
    height: 24px;
    background: transparent;
    border: none;
    padding: 0;
    outline: none;
    margin-left: 12px;
    margin-top: -2px;
  }
  .linkWrapper {
    display: flex;
  }
}
@media (max-width: 767px) {
  .detailsTablet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .optionImgTablet {
      margin-bottom: 5px;
    }
  }
  .deteilContentWrapper {
    padding: 0 0 0 0;
  }
  .edit {
    width: 24px;
    height: 24px;
    background: transparent;
    border: none;
    padding: 0;
    outline: none;
    margin-left: 12px;
    margin-top: -2px;
  }
  .linkWrapper {
    display: flex;
  }
}
.element::-webkit-scrollbar {
  width: 0 !important;
}
