.goal_select_lists_bottom {
  position: absolute;
  width: 100%;
  height: 10px;
  top: 190px;
}

.goal_select_lists {
  position: absolute;
  width: 100%;
  padding: 8px 16px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-color: #fff;
  z-index: 111;
  top: 45px;
  left: 0;
  border: 1px solid #e3e5e9;
  box-shadow: 0px 2px 10px 0px rgba(10, 15, 28, 0.15);
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px; // Set the width of the scrollbar
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1 !important; // Background of the scrollbar track
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ced0d4 !important; // Color of the scrollbar thumb
    border-radius: 10px; // Rounded corners
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #aeb0b5 !important; // Darker color on hover
  }

  /* Firefox scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: #ced0d4 #f1f1f1; // Thumb and track color for Firefox

  .goal_select_list {
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
    padding: 4px 12px;
    border-left: 4px solid;
    border-radius: 2px;
    cursor: pointer;

    h3 {
      color: #1b2a3d;
      font-weight: 500;
      font-size: 13px;
      font-family: "Inter";
      margin: 0;
      padding: 0;
      line-height: 20px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: normal;
      word-break: break-word;
      -webkit-line-clamp: 2;
    }
  }

  .createGaol {
    padding: 18px 0px;

    button {
      background: #1271a6;
      padding: 6px 34px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      border: none;
      border-radius: 2px;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      outline: none;
    }
  }
}

.goal_select {
  position: absolute;
  width: 100%;
  height: 200px;
  background-color: #fff;
  z-index: 111;
  top: 45px;
  left: 0;
  border: 1px solid #e3e5e9;
  box-shadow: 0px 2px 10px 0px rgba(10, 15, 28, 0.15);
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h3 {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    color: #8c959f;
    margin-top: 46px;
    margin-bottom: 20px;
  }

  button {
    background: #1271a6;
    padding: 6px 12px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    outline: none;
    margin-bottom: 16px;
  }

  span {
    color: #1271a6;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }
}

.goal_search {
  position: relative;
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  margin: 6px 0px 10px;

  input {
    width: 100%;
    border: none;
    outline: none;
    background-color: #f5f5f5;
    color: #737373;
    padding-right: 20px;
    font-weight: 400;
    font-size: 14px;

    &::placeholder {
      color: #737373 !important;
      font-weight: 400;
    }
  }

  svg {
    position: absolute;
    right: 12px;
  }
}

.noGoal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;

  h3 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    color: #737373;
  }
}
